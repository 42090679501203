import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { NgxFhirQnrFormsRoutingModule } from '@hestia/ngx-fhir-qnr-forms';
import { HestiaMitIdCallbackComponent } from '@hestia/ngx-mitid';
import {
  ErrorComponent,
  HomeComponent,
  LoginComponent,
  LoginPageData,
  SSNEntryComponent,
  SSNEntryPageData,
} from '@hestia/ngx-pages';
import { AuthGuard, AuthNoneGuard } from '@hestia/ngx-user';

import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthNoneGuard],
    data: <LoginPageData>{
      imageSrc: '../assets/images/logo_no_bg_da-DK.png',
      footerLogoSrc: '../assets/images/region_nord_psychiatry_black_logo.png',
      auth: {
        usernameAndPassword: environment.appBuiltFor === 'KIOSK_APP',
        oneTimePassword: false,
        mitID: environment.appBuiltFor !== 'KIOSK_APP',
        mitIDLabel: 'Besvar spørgeskema',
      },
      userTemplates: [
        { login: 'demokiosk1', password: 'demodemo12' },
        { login: 'demopatient2023', password: 'demodemo12' },
      ],
      userTemplatesVisible: false,
      buttons: [
        {
          label: 'moreInformation',
          icon: 'information-circle-outline',
          color: 'light',
          handler: () =>
            window.open(
              'https://psykiatri.rn.dk/da/Service/Om-Psykiatrien/Brugerinddragelse/Individuel-brugerinddragelse/PRO-Psykiatri'
            ),
        },
      ],
      videoUrl:
        'https://video.rn.dk/19476789/37204471/20b69d6339a4c6c50677fff3ca469eec/video_medium/introduktion-til-pro-psykiatri-3-video.mp4',
      cards: [],
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      allowCreation: true,
      autoHandleCreateCarePlan: false
    },
  },
  {
    path: 'ssn-entry',
    component: SSNEntryComponent,
    canActivate: [AuthGuard],
    data: <SSNEntryPageData>{
      autoSubmit: false,
      buttons: [
        {
          label: 'moreInformation',
          icon: 'information-circle-outline',
          color: 'light',
          handler: () =>
            window.open(
              'https://psykiatri.rn.dk/da/Service/Om-Psykiatrien/Brugerinddragelse/Individuel-brugerinddragelse/PRO-Psykiatri'
            ),
        },
      ],
      videoUrl:
        'https://video.rn.dk/19476789/37204471/20b69d6339a4c6c50677fff3ca469eec/video_medium/introduktion-til-pro-psykiatri-3-video.mp4',
      defaultFaq: 'important',
      faqs: [
        {
          question: 'important',
          answer: 'importantDescription',
        },
      ],
    },
  },
  {
    path: 'quest',
    canActivate: [AuthGuard],
    loadChildren: () => NgxFhirQnrFormsRoutingModule,
  },
  {
    path: 'mitid-callback',
    component: HestiaMitIdCallbackComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslocoModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
